import React from "react";
import { withTranslation } from "react-i18next";
import "./Services.scss";

class Services extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <section className="site-section" ref={this.props.section}>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-12 text-center" data-aos="fade">
                            <h2 className="section-title mb-3">{t("Services")}</h2>
                        </div>
                    </div>

                    <div id="services" className="service">
                        <div className="service__block">
                            <img alt="web" src={require("../../assets/images/web.svg")} />
                            <div className="service__info">
                                <h6>{t('Web')}</h6>
                                <span>{t('WebDesc')}</span>
                            </div>
                        </div>
                        <div className="service__block">
                            <img alt="mobile" src={require("../../assets/images/mobile.svg")} />
                            <div className="service__info">
                                <h6>{t('Mobile')}</h6>
                                <span>{t('MobileDesc')}</span>
                            </div>
                        </div>
                        <div className="service__block">
                            <img alt="support" src={require("../../assets/images/support1.svg")} />
                            <div className="service__info">
                                <h6>{t('Support')}</h6>
                                <span>{t('SupportDesc')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    };
}

export default withTranslation()(Services);
