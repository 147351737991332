import React from "react";
import './Footer.scss';
import { withTranslation } from "react-i18next";
import connect from "react-redux/es/connect/connect";

// const iconPath = process.env.PUBLIC_URL + '/assets/images/';

class Footer extends React.Component {
    navigateSocial(e, social) {
        e.preventDefault();
        switch(social) {
            case "facebook":
                window.open('https://www.facebook.com/zirinc.ltd');
                break;
            case "instagram":
                break;
            case "twitter": 
                break;
            case "linkedin":
                window.open('https://www.linkedin.com/company/zirinc');
                break;
            default:
                break;
        }
    }


    render() {
        const { t } = this.props;
        return (

            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4 footer-about">
                                    <h2 className="footer-heading mb-4">{t('AboutUs')}</h2>
                                    <p>{t('AboutInfoShort')}</p>
                                </div>
                                <div className="col-md-4 ml-auto footer-links">
                                    <h2 className="footer-heading mb-4">{t('QuickLinks')}</h2>
                                    <ul className="list-unstyled ">
                                        <li><a href="/about" className="smoothscroll">{t('AboutUs')}</a></li>
                                        <li><a href="/services" className="smoothscroll">{t('Services')}</a></li>
                                        <li><a href="/career" className="smoothscroll">{t('Career')}</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-4 text-center socials">
                                    <h2 className="footer-heading mb-4">{t('FollowUs')}</h2>
                                    <a href="/" className="pl-0 pr-3" onClick={(e) => this.navigateSocial(e, 'facebook')}><span className="icon-facebook"></span></a>
                                    <a href="/" className="pl-3 pr-3" onClick={(e) => this.navigateSocial(e, 'twitter')}><span className="icon-twitter"></span></a>
                                    <a href="/" className="pl-3 pr-3" onClick={(e) => this.navigateSocial(e, 'instagram')}><span className="icon-instagram"></span></a>
                                    <a href="/" className="pl-3 pr-3" onClick={(e) => this.navigateSocial(e, 'linkedin')}><span className="icon-linkedin"></span></a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <h2 className="footer-heading mb-4">Subscribe Newsletter</h2>
                            <form action="#" method="post" className="footer-subscribe">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control border-secondary text-white bg-transparent" placeholder="Enter Email" aria-label="Enter Email" aria-describedby="button-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary text-black" type="button"
                                            id="button-addon2">Send
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div> */}
                    </div>
                    <div className="row pt-5 mt-5 text-center">
                        <div className="col-md-12">
                            <div className="border-top pt-5">
                                <p>
                                    &copy; ZIRINC MMC | {new Date().getFullYear()}
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </footer>


        );
    }
}

const mapStateToProps = (state) => ({
   
});


export default withTranslation()(connect(mapStateToProps)(Footer));
