import React from 'react';
import './Header.scss';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import { withTranslation } from "react-i18next";
import {navigateTo} from "../../redux/actions/navigationAction";
import i18n from './../../i18n';

const logo = process.env.PUBLIC_URL + '/images/';

class Header extends React.Component {

    nextPath(path) {
        this.props.navigateTo(path);
    }

    setLanguage(language) {
        i18n.changeLanguage(language);
    }

    render() {
        const {isGame, t} = this.props;
        return (
            <header className="site-navbar py-4 js-sticky-header site-navbar-target" role="banner">

                <div className="container">
                    <div className="row align-items-end">

                        <div className="col-6 col-xl-2">
                            <h1 className="mb-0 site-logo"><a href="/" className="h2 mb-0">
                                {isGame && <img className="header-logo" alt="logo" src={logo + 'zirinc_logo.png'}/>}
                                {!isGame && <img className="header-logo" alt="logo" src={logo + 'zirinc_white_transparent_cropped.png'}/>}
                            </a></h1>
                        </div>

                        <div className="col-12 col-md-10 d-none d-xl-block">
                            <nav className={`site-navigation position-relative text-right ${!isGame ? "nav-inverted" : ""}`} role="navigation">

                                <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                                    <li><span className="nav-link button-link" onClick={e => this.nextPath("home")}>{t('Home').toUpperCase()}</span></li>
                                    <li><span className="nav-link button-link" onClick={e => this.nextPath("about")}>{t('About').toUpperCase()}</span></li>
                                    <li><span className="nav-link button-link"
                                           onClick={e => this.nextPath("services")}>{t('Services').toUpperCase()}</span></li>
                                    <li><span className="nav-link button-link"
                                           onClick={e => this.nextPath("achievements")}>{t('Achievements').toUpperCase()}</span></li>
                                    <li><span className="nav-link button-link"
                                           onClick={e => this.nextPath("vacancies")}>{t('Career').toUpperCase()}</span></li>
                                    <li><span className="nav-link button-link"
                                           onClick={e => this.nextPath("contacts")}>{t('Contacts').toUpperCase()}</span></li>
                                    <li className="has-children">
                                        <span className="nav-link button-link">{i18n.language ? i18n.language.toUpperCase(): ""}</span>
                                        <ul className="dropdown">
                                            <li onClick={e => this.setLanguage('az')}><a>AZ</a></li>
                                            <li onClick={e => this.setLanguage('en')}><a>EN</a></li>
                                            <li onClick={e => this.setLanguage('ru')}><a>RU</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>


                        <div className="col-6 d-inline-block d-xl-none ml-md-0 py-3"
                             style={{position: "relative", top: "3px"}}>
                            <a href="#" className="site-menu-toggle js-menu-toggle float-right"><span
                                className="icon-menu h3"></span></a></div>
                    </div>
                </div>
            </header>
        );
    }

}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    navigateTo: (section) => navigateTo(section)
}, dispatch);


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));
