import {combineReducers} from "redux";

import navigationReducer from './navigationReducer';

import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
    router: connectRouter(history),
    navigationReducer
});
