import React from "react";
import './Game.scss';
import {bindActionCreators} from "redux";
import {navigateTo} from "../../redux/actions/navigationAction";
import {connect} from "react-redux";


const distance = 5;
const planeHeight = 20;
const planeWidth = 20;
const bulletWidth = 15;
const bulletHeight = 15;
const bulletSpeed = 20;
const gravity = 4;

function valueInRange(value, min, max)
{ 
	return (value >= min) && (value <= max);
}

function rectOverlap(A, B)
{
    const xOverlap = valueInRange(A.x, B.x, B.x + B.w) ||
                    valueInRange(B.x, A.x, A.x + A.w);

	const yOverlap = valueInRange(A.y, B.y, B.y + B.h) ||
                    valueInRange(B.y, A.y, A.y + A.h);

    return xOverlap && yOverlap;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

class Game extends React.Component {

    constructor() {
        super();
        this.state = getWindowDimensions();
    }
    
    handleResize() {
        this.setState(getWindowDimensions());
    }

	componentDidMount() {
		
	}

	componentDidUpdate(prevProps) {
		if(prevProps.isGame !== this.props.isGame && this.props.isGame === true) {
			const canvas = this.refs.canvas;
			this.sections = [
				{x: canvas.width * Math.random(), y: canvas.height * Math.random(), h: 20, w: 15, ref: 'home', vx: distance * (0.5 - Math.random()), vy: distance * (0.5 - Math.random()), name: "home"},
				{x: canvas.width * Math.random(), y: canvas.height * Math.random(), h: 20, w: 30, ref: 'services', vx: distance * (0.5 - Math.random()), vy: distance * (0.5 - Math.random()), name: "services"},
				{x: canvas.width * Math.random(), y: canvas.height * Math.random(), h: 20, w: 50, ref: 'achievements', vx: distance * (0.5 - Math.random()), vy: distance * (0.5 - Math.random()), name: "achievements"},
				{x: canvas.width * Math.random(), y: canvas.height * Math.random(), h: 50, w: 30, ref: 'about', vx: distance * (0.5 - Math.random()), vy: distance * (0.5 - Math.random()), name: "about"},
				{x: canvas.width * Math.random(), y: canvas.height * Math.random(), h: 20, w: 40, ref: 'contacts', vx: distance * (0.5 - Math.random()), vy: distance * (0.5 - Math.random()), name: "contacts"}
			];
			this.plane = {x: (canvas.width) / 2 - planeWidth / 2, y: canvas.height - planeHeight};
			this.interval = setInterval(() => this.iterate(), 100);
			this.bullets = [];
			this.explosions = [];
	
			window.addEventListener('resize', this.handleResizeHandler = () => this.handleResize());
		}
	}

	iterate() {
		const canvas = this.refs.canvas;
		const ctx = canvas.getContext("2d");
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		for (let section of this.sections) {
			//ctx.clearRect(section.x, section.y, section.w, section.h);
			
			section.x += section.vx;
			section.y += section.vy;

			if (section.y < 0) {
				section.y = - section.y;
				section.vy = - section.vy;
			}

			if (section.x < 0) {
				section.x = - section.x;
				section.vx = - section.vx;
			}

			if (section.y > canvas.height / 2) {
				section.y = canvas.height - section.y;
				section.vy = - section.vy;
			}

			if (section.x > canvas.width - section.w) {
				section.x = 2 * (canvas.width - section.w) - section.x;
				section.vx = - section.vx;
			}

			ctx.drawImage(this.refs[section.ref], section.x, section.y, section.w, section.h);
		}

		let bullets = [];
		ext:
		for (let bullet of this.bullets) {
			bullet.y -= bulletSpeed;

			if (bullet.y < -bulletHeight) {
				continue;
			}
			
			for (let section of this.sections) {
				if (rectOverlap(section, bullet)) {
					this.explosions.push({x: bullet.x, y: bullet.y, stage: 0, section: section});
					continue ext;
				}
			}
			bullets.push(bullet);
			ctx.drawImage(this.refs.bullet, bullet.x, bullet.y, bulletWidth, bulletHeight);
		}

		for (let explosion of this.explosions) {
			let offsetX = (explosion.stage % 5) * 130;
			let offsetY = Math.floor(explosion.stage / 5) * 130;
			ctx.drawImage(this.refs.explosion, offsetX, offsetY, 130, 130, explosion.x-75, explosion.y-75, 130, 130);
			if(explosion.stage === 25) {
				this.sectionClicked(explosion.section.name);
				return;
			}
			explosion.stage++;
		}
		
//		console.log(bullets);
		this.bullets = bullets;

		if (this.cursor) {
            this.plane.x += (this.cursor.x - this.plane.x - planeWidth / 2) / gravity;
            const canvas = this.refs.canvas;
		    this.plane.y = canvas.height - planeHeight;
		}
		ctx.drawImage(this.refs.plane, this.plane.x, this.plane.y);
	}

	sectionClicked(section) {
		this.props.navigateTo(section);
	}

	onMouseClick(event) {
		const ctx = this.refs.canvas.getContext("2d");
		const bullet = {x: this.plane.x - bulletWidth / 2 + planeWidth / 2, y: this.refs.canvas.height - planeHeight - bulletHeight, h: bulletHeight, w: bulletWidth};
		ctx.drawImage(this.refs.bullet, bullet.x, bullet.y, bulletWidth, bulletHeight);
		this.bullets.push(bullet);
	}

	onMouseMove(event) {
		this.cursor = {
			x: (event.nativeEvent.offsetX / event.target.getBoundingClientRect().width) * event.target.width,
			y: (event.nativeEvent.offsetY / event.target.getBoundingClientRect().height) * event.target.height};
	}

	componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.handleResizeHandler);
	}

	render(){

		return(
			<div>
				<canvas ref="canvas" height={this.state.height} width={this.state.width} className="game" onMouseMove={this.onMouseMove.bind(this)} onClick={this.onMouseClick.bind(this)}/>
				<img alt="home" ref="home" src={'https://media.gettyimages.com/vectors/vector-pixel-icon-isolated-8bit-graphic-element-earth-globe-sign-vector-id587818186?s=2048x2048'} className="hidden" />
				<img alt="services" ref="services" src={'https://media.gettyimages.com/vectors/vector-pixel-icon-isolated-8bit-graphic-element-earth-globe-sign-vector-id587818186?s=2048x2048'} className="hidden" />
				<img alt="about" ref="about" src={'https://media.gettyimages.com/vectors/vector-pixel-icon-isolated-8bit-graphic-element-earth-globe-sign-vector-id587818186?s=2048x2048'} className="hidden" />
				<img alt="achievements" ref="achievements" src={'https://media.gettyimages.com/vectors/vector-pixel-icon-isolated-8bit-graphic-element-earth-globe-sign-vector-id587818186?s=2048x2048'} className="hidden" />
				<img alt="contacts" ref="contacts" src={'https://media.gettyimages.com/vectors/vector-pixel-icon-isolated-8bit-graphic-element-earth-globe-sign-vector-id587818186?s=2048x2048'} className="hidden" />
				<img alt="plane" ref="plane" src={'/sprites/minus4.png'} className="hidden" />
				{/* <img alt="bullet" ref="bullet" src={'/sprites/bullet.png'} className="hidden" /> */}
				{/* <img alt="paddle" ref="plane" src={'/sprites/paddle2.png'} className="hidden" /> */}
				<img alt="ball" ref="bullet" src={'/sprites/ball.png'} className="hidden" />
				<img alt="explosion" ref="explosion" src={'/sprites/explosion.png'} className="hidden" />
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
	navigateTo: (section) => navigateTo(section)
}, dispatch);

export default connect(null, mapDispatchToProps)(Game);
