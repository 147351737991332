export const NAVIGATE_TO = 'NAVIGATE_TO';

export function navigateTo(section){
    return {
        type: NAVIGATE_TO,
        payload: section
    }
}

export const scrollToSection = (currentSection) => {
    if (currentSection && currentSection.object && currentSection.object.current) {
        window.scrollTo(0, currentSection.object.current.offsetTop);
    }
};

