import React from "react";
import {connect} from "react-redux";
import './Contacts.scss';
import { withTranslation } from "react-i18next";

class Contacts extends React.Component {
    
    render() {
        const { t } = this.props;
        return (
            <section className="contacts site-section bg-light" ref={this.props.section} data-aos="fade">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center">
                            <h2 className="section-title mb-3">{t('Contacts')}</h2>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-4 text-center">
                            <p className="mb-4">
                                <span className="icon-room d-block h4 icon-red"></span>
                                <a className="address" href="https://www.google.com/maps/place/Minera+MMC/@40.4135826,49.8575779,21z/data=!4m5!3m4!1s0x403087ff2a64c273:0xe1c2b5cd66bff72c!8m2!3d40.4135572!4d49.85762">{t('ContactAddress')}</a>
                            </p>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="mb-4">
                                <span className="icon-phone d-block h4 icon-red"></span>
                                <div>{t('ContactNumber')}</div>
                                <div>{t('ContactNumber2')}</div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <p className="mb-0">
                                <span className="icon-mail_outline d-block h4 icon-red"></span>
                                <a href="/">{t('ContactEmail')}</a>
                            </p>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-12 mb-5">


                            <form action="#" className="p-5 bg-white">

                                <h2 className="h4 text-black mb-5">Contact Form</h2>

                                <div className="row form-group">
                                    <div className="col-md-6 mb-3 mb-md-0">
                                        <label className="text-black" htmlFor="fname">First Name</label>
                                        <input type="text" id="fname" className="form-control" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="text-black" htmlFor="lname">Last Name</label>
                                        <input type="text" id="lname" className="form-control" />
                                    </div>
                                </div>

                                <div className="row form-group">

                                    <div className="col-md-12">
                                        <label className="text-black" htmlFor="email">Email</label>
                                        <input type="email" id="email" className="form-control" />
                                    </div>
                                </div>

                                <div className="row form-group">

                                    <div className="col-md-12">
                                        <label className="text-black" htmlFor="subject">Subject</label>
                                        <input type="subject" id="subject" className="form-control" />
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label className="text-black" htmlFor="message">Message</label>
                                        <textarea name="message" id="message" cols="30" rows="7"
                                                  className="form-control"
                                                  placeholder="Write your notes or questions here..."></textarea>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <input type="submit" value="Send Message"
                                               className="btn btn-primary btn-md text-white submit-button" />
                                    </div>
                                </div>


                            </form>
                        </div> */}

                    {/* </div> */}
                </div>
            </section>
            );
    };
}


const mapStateToProps = (state) => ({
});

export default  withTranslation()(connect(mapStateToProps, null)(Contacts));
