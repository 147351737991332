import React from 'react';
import './Home.scss';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import { withTranslation } from "react-i18next";
import {navigateTo} from "../../redux/actions/navigationAction";
import Game from "../Game/Game"; 
import Header from "../Header/Header";

class Home extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
            isGame: false
        };
    }

    playTheGame() {
        this.setState({ isGame: !this.state.isGame })
    }

    render() {
        const { t } = this.props;
        const { isGame } = this.state;
        return (
            <div className={`home-page ${!isGame ? "home-page__dropped" : ""}`}>
                <Header isGame={isGame}/>
                {!isGame ? <div className="home-page__slogan">
                    <h2 className="section-title">{t('MainSlogan')}</h2>
                    <h4 className="section-sub-title-2">{t('MainSubSlogan')}</h4>
                </div>: null}
                {/* {!isGame && <button className="home-page__btn-play" onClick={() => this.playTheGame()}>{t('PlayButton')}</button>} */}
                <Game isGame={isGame} />
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    navigateTo: (section) => navigateTo(section)
}, dispatch);


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Home));
