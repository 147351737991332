import React from 'react';
import './Main.scss';
import Home from "../Home/Home";
import Achievements from "../Achievements/Achievements";
import Contacts from "../Contacts/Contacts";
import Footer from "../Footer/Footer";
import Vacancies from "../Vacancies/Vacancies";
import Services from "../Services/Services";
import {connect} from "react-redux";
import {scrollToSection} from "../../redux/actions/navigationAction";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";

class Main extends React.Component {

    componentDidMount() {
        scrollToSection(this.props.currentSection);
    }

    componentDidUpdate() {
        const path = this.props.currentSection.name;
        if (path === this.props.currentRoute.substr(1, this.props.currentRoute.length - 1)) {
            scrollToSection(this.props.currentSection);
        } else {
            this.props.changePage(path);
        }
    }

    render() {
        return (
            <div>
                <Home section={this.props.sections.find(s => s.name === "home").object}/>
                <Services section={this.props.sections.find(s => s.name === "services").object}/>
                <Achievements section={this.props.sections.find(s => s.name === "achievements").object}/>
                <Vacancies section={this.props.sections.find(s => s.name === "vacancies").object}/>
                <Contacts section={this.props.sections.find(s => s.name === "contacts").object}/>
                <Footer/>
                {/*<NavigateHome />*/}
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    sections: state.navigationReducer.sections,
    currentSection: state.navigationReducer.currentSection,
    currentRoute: state.router.location.pathname
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    changePage: (path) => push(path)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Main);
