import React from 'react';
import './App.scss';
import {Route} from "react-router-dom";
import Main from "./components/Main/Main";

function App() {
    return (
        <div>
            <Route exact path="/" component={Main}/>
            <Route exact path="/home" component={Main}/>
            <Route exact path="/services" component={Main}/>
            <Route exact path="/vacancies" component={Main}/>
            <Route exact path="/achievements" component={Main}/>
            <Route exact path="/about" component={Main}/>
            <Route exact path="/contacts" component={Main}/>
        </div>
    );
}

export default App;
