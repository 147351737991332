import React from "react";
import {NAVIGATE_TO} from "../actions/navigationAction";

const sections = [
    {name: "home", object: React.createRef()},
    {name: "services", object: React.createRef()},
    {name: "achievements", object: React.createRef()},
    {name: "vacancies", object: React.createRef()},
    {name: "about", object: React.createRef()},
    {name: "contacts", object: React.createRef()}
];

const INITIAL_STATE = {
    sections: sections,
    currentSection: sections[0]
};

const navigationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NAVIGATE_TO:
            return {
                ...state, currentSection: state.sections.find(s => s.name === action.payload)
            };
        default:
            return state;
    }
};

export default navigationReducer;
