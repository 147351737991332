import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import { withTranslation } from "react-i18next";
import "./Achievements.scss";

class Achievements extends React.Component {

    navigateToApp(e, app) {
        e.preventDefault();
        switch(app) {
            case "epul":
                window.open('https://www.epul.az');
                break;
            case "pulpal":
                window.open('https://www.pulpal.az');
                break;
            case "usaq": 
                window.open('https://play.google.com/store/apps/details?id=com.usaqqaynarxetti');
                break;
            case "uptodate":
                window.open('https://play.google.com/store/apps/details?id=com.uimconsulting.reservationapplication');
                break;
            case "videodoctor":
                window.open('https://videodoktor.az/');
                break;
            case "sorgular":
                window.open('https://sorgular.az/');
                break;
            case "md-reference":
                window.open('https://mdreference.az/');
                break;
            case "self-checker":
                break;
            case "dermanlarim":
                window.open('https://dermanlarim.az/');
                break;
            default:
                break;
        }
    }

    render() {
        const { t } = this.props;
        return (
            <section className="site-section bg-light" ref={this.props.section}>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-12 text-center" data-aos="fade">
                            <h2 className="section-title mb-3">{t("Achievements")}</h2>
                        </div>
                    </div>
                    <Carousel
                        className="portfolio-carousel"
                        interval={2000}
                        nextIcon={<img alt="arrow-right" className="portfolio-carousel__arrow" src={require("../../assets/images/right-arrow.svg")} />}
                        prevIcon={<img alt="arrow-left" className="portfolio-carousel__arrow" src={require("../../assets/images/left-arrow.svg")} />}
                    >
                        <Carousel.Item>
                            <div className="portfolio-carousel__item">
                                <img
                                    src={require("../../assets/images/epul-logo.png")}
                                    alt="epul"
                                />
                                <div>
                                    <p>{t("EpulDesk")}</p>
                                    <a href="#" onClick={(e) => this.navigateToApp(e, "epul")}>{t("Navigate")}</a>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="portfolio-carousel__item">
                                <img
                                    src={require("../../assets/images/logo-pulpal-full.svg")}
                                    alt="pulpal"
                                />
                                <div>
                                    {/* <h3>PulPal</h3> */}
                                    <p>{t("PulpalDesc")}</p>
                                    <a href="#" onClick={(e) => this.navigateToApp(e, "pulpal")}>{t("Navigate")}</a>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </div>

                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="portfolio-carousel__item">
                                <img
                                    src={require("../../assets/images/usag-q-x.png")}
                                    alt="usaq"
                                />
                                <div>
                                    <p>{t("UsaqDesc")}</p>
                                    <a href="#" onClick={(e) => this.navigateToApp(e, "usaq")}>{t("Navigate")}</a>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="portfolio-carousel__item">
                                <img
                                    src={require("../../assets/images/uptodate.png")}
                                    alt="uptodate"
                                />
                                <div>
                                    <p>{t("UptodateDesc")}</p>
                                    <a href="#" onClick={(e) => this.navigateToApp(e, "uptodate")}>{t("Navigate")}</a>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="portfolio-carousel__item">
                                <img
                                    src={require("../../assets/images/videodoctor.png")}
                                    alt="videodoctor"
                                />
                                <div>
                                    <p>{t("VideodoctorDesc")}</p>
                                    <a href="#" onClick={(e) => this.navigateToApp(e, "videodoctor")}>{t("Navigate")}</a>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="portfolio-carousel__item">
                                <img
                                    src={require("../../assets/images/sorgular.png")}
                                    alt="sorgular"
                                />
                                <div>
                                    <p>{t("SorgularDesc")}</p>
                                    <a href="#" onClick={(e) => this.navigateToApp(e, "sorgular")}>{t("Navigate")}</a>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="portfolio-carousel__item">
                                <img
                                    src={require("../../assets/images/md-reference.png")}
                                    alt="md-reference"
                                />
                                <div>
                                    <p>{t("MDReferenceDesc")}</p>
                                    <a href="#" onClick={(e) => this.navigateToApp(e, "md-reference")}>{t("Navigate")}</a>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="portfolio-carousel__item">
                                <img
                                    src={require("../../assets/images/dermanlarim.png")}
                                    alt="dermanlarim"
                                />
                                <div>
                                    <p>{t("DermanlarimDesc")}</p>
                                    <a href="#" onClick={(e) => this.navigateToApp(e, "dermanlarim")}>{t("Navigate")}</a>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                    </Carousel>

                </div>
            </section>
        )
    };
}

export default withTranslation()(Achievements);
