import React from "react";
import { withTranslation } from "react-i18next";
import "./Vacancies.scss";

class Vacancies extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <section className="site-section" ref={this.props.section}>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-12 text-center" data-aos="fade">
                            <h2 className="section-title mb-3">{t('Career')}</h2>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 text-center" data-aos="fade">
                            <h2 className="section-sub-title-2 mb-3">{t('CareerSlogan')}</h2>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-12 text-center" data-aos="fade">
                            <h2 className="section-sub-title mb-3">{t('OpenPositions')}</h2>
                        </div>
                    </div>

                    <div id="vacancies">
                       <div className="career-card career-card_margin">
                            <div className="career-card__img">
                                <img alt="sys-admin" src={require("../../assets/images/sysadmin3.png")}/>
                            </div>
                            <div className="career-card__info">
                                <h2 className="career-card__heading">{t("Vacancies.SystemAdministrator")}</h2>
                                <p className="career-card__tags">
                                    {t("Vacancies.SysAdminTags")}
                                </p>
                            </div>
                       </div>

                       <div className="career-card career-card_margin">
                            <div className="career-card__img">
                                <img alt="tester" src={require("../../assets/images/qa.png")}/>
                            </div>
                            <div className="career-card__info">
                                <h2 className="career-card__heading">{t("Vacancies.Tester")}</h2>
                                <p className="career-card__tags">
                                    {t("Vacancies.TesterTags")}
                                </p>
                            </div>
                       </div>

                       <div className="career-card career-card_margin">
                            <div className="career-card__img">
                                <img alt="frontend" src={require("../../assets/images/frontend.png")}/>
                            </div>
                            <div className="career-card__info">
                                <h2 className="career-card__heading">{t("Vacancies.Frontend")}</h2>
                                <p className="career-card__tags">
                                    {t("Vacancies.FrontendTags")}
                                </p>
                            </div>
                       </div>

                       <div className="career-card career-card_margin">
                            <div className="career-card__img">
                                <img alt="backend" src={require("../../assets/images/backend.png")}/>
                            </div>
                            <div className="career-card__info">
                                <h2 className="career-card__heading">{t("Vacancies.Backend")}</h2>
                                <p className="career-card__tags">
                                    {t("Vacancies.BackendTags")}
                                </p>
                            </div>
                       </div>

                       <div className="career-card career-card_margin">
                            <div className="career-card__img">
                                <img style={{opacity: '70%'}} alt="noting-else" src={require("../../assets/images/puzzle2.png")}/>
                            </div>
                            <div className="career-card__info">
                                <h2 className="career-card__heading">
                                    {t("Vacancies.NothingElse")}
                                    {/* {t("Vacancies.Nothing")} */}
                                </h2>
                                <p className="career-card__tags">
                                    {t("Vacancies.NothingTags")}
                                </p>
                            </div>
                       </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-12 text-center" data-aos="fade">
                            <h2 className="section-sub-title-4 mt-5 mb-3">{t('ToApply')}</h2>
                        </div>
                    </div>
                </div>
            </section>
        )
    };
}

export default withTranslation()(Vacancies);
